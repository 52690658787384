import React from "react"

import './Product.css';

class ProductPlaceholder extends React.Component{
    render() {
      return ( 
      <div className="boxPlaceholder"> 
        < div className = "imagePlaceHolder stub" > </div>   
        <div className = "buttonPlaceHolder stub">  </div>     
      </ div>
    );
    }
}
export default ProductPlaceholder;
import React from 'react';
import Image from 'react-bootstrap/Image'
import Spinner from "./components/Spinner.js"
import './Home.css';
import logo from './logo.png'
import Product from "./Product.js"
import parcel from './parcel.png'
import CookieConsent from "react-cookie-consent";
import graph from "./mygraph.png"
import FigureImage from "react-bootstrap/FigureImage"
import FigureCaption from 'react-bootstrap/FigureCaption' 
import Figure from 'react-bootstrap/Figure'
import RandomProduct from './components/RandomProduct'

import massage from "./images/massage.jpg"
import grooming_set from "./images/grooming_set.jpg"
import whiskey_set from "./images/whiskey_set.jpg"
import yeti_coffee_bottle from "./images/yeti_coffee_bottle.jpg"
import fifty_states_50_ideas from "./images/50_states_50_ideas.jpg"
import sandwich_maker from "./images/sandwich_maker.jpg"
import laptop_backpack from "./images/laptop_backpack.jpg"
import universal_socket_grip from "./images/universal_socket_grip.jpg"
import self_defense_pen from "./images/self_defense_pen.jpg"
import skecher_sneaker from "./images/skecher_sneaker.jpg"

import burrito_blanket from "./images/burrito_blanket.jpg"
import crocks_black from "./images/crocks_black.jpg"
import birthday_mug from "./images/birthday_mug.jpg"
import adilette_women from "./images/adilette_women.jpg"
import ck_underwear from "./images/ck_underwear.jpg"
import jessica_simpson_shoes from "./images/jessica_simpson_shoes.jpg"
import relax_set from "./images/relax_set.jpg"
import nail_set from "./images/nail_set.jpg"
import green_wallet from "./images/green_wallet.jpg"
import nivea_set from "./images/nivea_set.jpg"

import kids_gift from "./images/kids_gift.jpg"
import tracing_pad from "./images/tracing_pad.jpg"
import smart_kids_riddles from "./images/smart_kids_riddles.jpg"
import fivethousand_awesome_facts from "./images/fivethousand_awesome_facts.jpg"
import arrow_toy from "./images/arrow_toy.jpg"

class Home extends React.Component {
 componentDidMount () {
    const script = document.createElement("script");

    script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=00352fc9-da6b-476d-b7e9-c131e29c467a";
    script.async = true;
    document.body.appendChild(script);
}
 render() {
   
   return( 
  <div>
<RandomProduct/>
<div id = "AdWrapper"
 style = {{
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
 }}
 >
<div id="amzn-assoc-ad-00352fc9-da6b-476d-b7e9-c131e29c467a" style = {{width: "75%"}}></div>
</div>
<div className = "MainContainer2">
  <h1><b>Random products on Amazon</b></h1>
  <h3>5 steps to find creative inspiration for the perfect gift</h3>
    <p>You may be in the situation that you are looking for a gift for a friend, family member or your partner. You may have already selected a random product on Amazon that you would like to order, but you have the feeling that it is not the right gift yet. </p>
    <p>Then you've come to the right place! I will show you how to find the perfect gift in 5 steps instead of the random product from Amazon. </p>
    <p>Remember: A gift is also an expression of appreciation! A bad or wrong gift may be negatively associated with you in the long run, because the person may think that you don't like them very much. You don't want to be the one who is remembered with this, do you? </p>
    <p>Follow these 5 steps and you will find the perfect gift! </p>
  <h3><b> 1. Who is the person being given the gift?</b></h3>
    <p>This is the first question you should ask. Think about the person's hobbies, profession, character traits and preferences. Find out which celebrities they follow, what websites they like, and what topics they are interested in. Make a short list so that you don't forget your ideas!</p>
  <h3><b>2. What is your budget?</b> </h3>
    <p>Since the range of products on Amazon and other websites like eBay is huge, your next step is to set a price range. This will narrow down your selection and give you a better overview. </p>
    <p>Tip: If you have received a gift from this person in the past, what price range was it in? Orientate yourself by this. It can hurt the other person or even make you feel guilty if you don't value him enough with a gift that is too cheap or embarrass him with one that is too expensive. </p>
  <h3><b>3. What did the person tell you?</b></h3>
    <p>The person may have told you about random stuff that they don't have yet but have been wanting for ages. Think about the apartment they live in. Is there anything that would fit in there? Is there maybe something you bought on Amazon recently that you really liked? Maybe your friend liked this article as well. The best products are often those that we ourselves have found to be good!</p>
  <h3><b>4. Question friends of the person for advice</b></h3>
    <p>Sometimes it can help if you involve other people. Together you have more ideas and can inspire each other. A trick that you should not use too often, however: If you are invited to a birthday party and are therefore looking for the gift, ask other guests what they want to give and join someone who has already found a gift and participate financially in their gift. Maybe there is also something that would go very well with this gift and so you find your own gift.</p>
  <h3><b>5. None of the previous suggestions helped?</b></h3>
    <p>If your gift is still the random product from Amazon that you had in the beginning, then you should look for inspiration! One problem is that we often only search in the categories we already know. This limits our view. To get around this problem, we have developed this website. Test our application and let it surprise you. We have created a database of Amazon's different categories to surprise you with unknown products! Take the chance to find products you never thought of before. Now you can find the perfect gift with random stuff from Amazon! Have fun trying out!</p>
  
  <h1><b>The 25 best Random Products on Amazon</b></h1>
    <p>
      If you are lazy and don't feel like looking through random stuff from Amazon.
      Then we have picked out the best stuff for you here! Maybe it's a situation you know:  It's not long before you have to give someone a gift and your stress level rises and rises because you just can't think of anything.
    </p>
<div className= "ImageWrapper" 
  style = {{
    position: "relative",
    margin: "auto",
    width: "50%",
    height: "37.5%",
  }}
> 
  <Figure>
    <Figure.Image 
      src= {graph} 
    />
  </Figure>
</div>
<div className = "imageTextBox"> 
  <p id = "imageText" style = {{
      textAlign: "center",
      fontSize: "small",
    }}
  >
    <i>Days until a friend's birthday without a gift..</i>
  </p>
</div>
<p>
The stupid thing is that sometimes you don't get any ideas at all because you are so stressed.
</p>
<span id = "bigText" style = {
  { fontSize : "large", textAlign: "center", margin: "3%"}}>
  <center>
    <b>
      Then we have solution for you!
    </b>
  </center>
</span>
<p>
We'll show you the coolest, craziest random stuff you can find on the internet and at the same time you'll get the perfect gift. 
You don't believe that? Then check out the following list and make sure you were wrong.
</p>
<h2>
  <b>Best Random Gifts for Men</b>
</h2>
<p>
If your husband does a lot of sports and often has muscle aches, then a massager would be just right for his tired muscles. 
The advantage: You don't have to massage him all the time!
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ massage } alt= "Shoulder and Neck Massager"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Shiatsu Back Shoulder and Neck Massager with Heat, Electric Deep Tissue 4D Kneading Massage for Shoulder, Back and Neck
        </h4>
      </div>
      <a href= "https://amzn.to/359TY8Z">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
A real man has a beard. And a real man's beard is well-groomed. To make sure this always works, 
there is this cool set, so your man always has the perfect beard!
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ grooming_set } alt= "Beard and Grooming Kit"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Beard Grooming & Trimming Kit for Men Care - Beard Brush, Beard Comb, 
        Unscented Beard Oil Leave-in Conditioner, 
        Mustache & Beard Balm Butter Wax, Barber Scissors for Styling, 
        Shaping & Growth Gift set
        </h4>
      </div>
      <a href= "https://amzn.to/2U589FW">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
A whisky is not a normal drink. The drinking of whisky must be celebrated. It needs the right vessel and the right glasses. 
This Italian glass set and decanter makes a man's heart beat faster.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ whiskey_set } alt= "Random Product - Whiskey Set"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Paksh Novelty 7-Piece Italian Crafted Glass Decanter & Whisky 
          Glasses Set, Elegant Whiskey Decanter with Ornate Stopper and 6 Exquisite Cocktail Glasses
        </h4>
      </div>
      <a href= "https://amzn.to/3kcHNfz">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
Coffee is a great drink. How else could we get up every morning? But there's nothing worse than cold coffee. 
Fortunately that's all over now!
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ yeti_coffee_bottle } alt="Yeti Coffee Bottle"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
            YETI Rambler 20 oz Tumbler, 
            Stainless Steel, Vacuum Insulated with MagSlider Lid
        </h4>
      </div>
      <a href= "https://amzn.to/2GCIaCy">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
There's always an adventurer in a man. So that your adventurer doesn't run out of ideas and you can plan 
your next trip, you'll find some great inspirations in this book.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ fifty_states_50_ideas } alt="Random Gift - Book about fifty states"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          50 States, 5,000 Ideas: Where to Go, 
          When to Go, What to See, What to Do
        </h4>
      </div>
      <a href= "https://amzn.to/2Ii556S">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
Breakfast is the most important meal of the day, my grandmother used to say. We think that it should also be the tastiest! Sometimes it can be quite 
time-consuming. Fortunately not with this Breakfast Sandwick Maker.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ sandwich_maker }
           alt="Breakfast Sandwich Maker"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Hamilton Beach Breakfast Sandwich Maker, Silver (25475A)
        </h4>
      </div>
      <a href= "https://amzn.to/3lb43YF">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
This water-repellent backpack with charging function is the perfect companion for all kinds 
of trips and activities. So nothing stays at home anymore.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ laptop_backpack }
           alt= "Random Amazon Product - Laptop Backpad water-resistant"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Travel Laptop Backpack Water Resistant Anti-Theft Bag with USB Charging Port and Lock 14/15.6 Inch Computer Business 
          Backpacks for Women Men College School Student Gift,
          Bookbag Casual Hiking Daypack
        </h4>
      </div>
      <a href= "https://amzn.to/3ezC4iM">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
This UNIVERSAL SOCKET wrench adapter for power drills and 
ratchet wrenches that instantly adjusts to grip hex nuts, hooks, eye 
and lag screws, bolt heads, and other various shape and size objects. This magic grip is perfect for home improvement, 
construction is perfect for arpentry, auto repair, and other household maintenance. 
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ universal_socket_grip }
           alt="Universal Socket Grip"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        RAK Universal Socket Grip (7-19mm) Multi-Function Ratchet Wrench 
        Power Drill Adapter 2Pc Set - Best Unique Tool Gift for Men
        </h4>
      </div>
      <a href= "https://amzn.to/2U0w35v">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
It is always wise to be able to defend yourself in every situation. 
A pen is particularly inconspicuous and is not detected by 
potential attackers.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ self_defense_pen }
           alt="Self Defense Pen Gift"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Tactical Pen for Self-Defense + LED Tactical Flashlight, 
          Bottle Opener, Window Breaker | Multi-Tool for Everyday Carry (EDC) 
        </h4>
      </div>
      <a href= "https://amzn.to/2IesyGe">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
Another random product on Amazon that makes a great gift 
for your husband is this comfortable sneaker from Skechers, 
which is a great choice for both free time and casual outfits at work.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ skecher_sneaker }
           alt="Skecher Sneaker"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Skechers Men's Classic Fit-Delson-Camden Sneaker
        </h4>
      </div>
      <a href= "https://amzn.to/3ezEApk">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<h2>
  <b>Best Random Gifts for Women</b>
</h2>
<p>
With this funny blanket you will make your girlfriend or 
wife laugh and guaranteed she won't freeze on the sofa anymore.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ burrito_blanket }
           alt="Amazon Random Product - Burrito Blanket"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        CASOFU Burritos Blanket, Giant Flour Tortilla Throw Blanket, 
        Novelty Tortilla Blanket for Your 
        Family, Soft and Comfortable Flannel Taco Blanket 
        for Adults. (Burrito-a, 71 inches)
        </h4>
      </div>
      <a href= "https://amzn.to/2JInDO5">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
Crocs are great because your feet don't sweat inside them because they 
are always surrounded by air and they are great to wear in the 
garden because they are easy to wash when they get dirty.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ crocks_black }
           alt="Crocs black for Women"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Crocs Classic Clog|Comfortable Slip-on Casual Water Shoe
        </h4>
      </div>
      <a href= "https://amzn.to/2IegQeJ">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
On your birthday you should drink from a cup that really shows how 
special this day is. This mug is the right funny random product for that!
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ birthday_mug }
           alt="Birthday gift mug for girlfriend"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Jumway Not a Day Over Fabulous mug - Birthday Gifts for Women 
          - Funny Birthday Gift
        </h4>
      </div>
      <a href= "https://amzn.to/32oNuRM">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
The Adilette is a classic and everyone should own a pair of them. 
Those who already have some, know what they are talking about. 
Have you ever worn anything more comfortable?
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ adilette_women }
           alt="Adilette Women"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          adidas Women's Adilette Aqua Slide Sandal
        </h4>
      </div>
      <a href= "https://amzn.to/3p6WjcF">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
Underwear from Calvin Klein is more than just a piece of fabric. 
They are both comfortable and sexy. It always leaves all options open..
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ ck_underwear }
           alt="Calvin Klein underwear - perfect birthday present for wife"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Calvin Klein Underwear Women's Modern Cotton Bralette
        </h4>
      </div>
      <a href= "https://amzn.to/2Id17MQ">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
If your better half always has cold feet in the house, then these 
plush slippers by Jessica Simpson are the perfect gift for her.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ jessica_simpson_shoes }
           alt="Jessica Simpson Shoes"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Jessica Simpson Comfy Faux 
          Fur Womens House Slipper Scuff Memory Foam Slip On Anti-Skid Sole
        </h4>
      </div>
      <a href= "https://amzn.to/3paCQIe">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
After a busy day, your wife deserves to relax and unwind at home. 
If you lazy guy doesn't help her with that, then you should at least 
give her this set so that your wife can turn your bathroom into her 
personal wellness temple.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ relax_set }
           alt="Random product from amazon - Relax Set"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Women: Relaxing at Home Spa Kit Scented with Lavender and Jasmine 
        - Includes Large Bath Bombs, Salts, Shower Gel, Body Butter
        </h4>
      </div>
      <a href= "https://amzn.to/3mYNYpB">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
With this manicure and pedicure set your wife will be busy for 
some time in a month to make her nails look perfect again. And you know 
what that means for you? You have a few more hours to do what you want 
to do and you are completely undisturbed. Sounds great, right?
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ nail_set }
           alt="Nail Set as a gift"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Manicure Set, MH ZONE Pedicure Set Nail Clippers, 19 Pieces 
        Premium Stainless Steel Manicure Kit with Portable Travel Case
        </h4>
      </div>
      <a href= "https://amzn.to/3eBrLuz">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
You know what's almost as important as a perfect bag? No. Well, 
then take a closer look at this purse. To women, it's more than 
just a storage place for cash and credit cards. 
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ green_wallet }
           alt="Great random gift - green wallet"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
        Travelambo Womens Walllet RFID Blocking Bifold 
        Multi Card Case Wallet with Zipper Pocket
        </h4>
      </div>
      <a href= "https://amzn.to/34bt1Q7">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
With some care products you are always on the right side in case of doubt 
if you have not yet found the perfect gift. Still better than a 
random product you buy the day before her birthday, right?
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ nivea_set }
           alt= "Nivea set for wife"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          NIVEA Pamper Time Gift Set - 5 Piece Luxury Collection 
          of Moisturizing Products and Travel Bag Included
        </h4>
      </div>
      <a href= "https://amzn.to/38ke0zx">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<h2>
  <b>Best Random Products for Kids</b>
</h2>
<p>
One starts early to practice who wants to become an engineer or 
architect later. With this toy you lay the right foundation for this.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ kids_gift }
           alt= "Random Product for Kids - Toys Kit"/>
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          101 Piece STEM Toys Kit, Educational Construction Engineering Building Blocks Learning Set
        </h4>
      </div>
      <a href= "https://amzn.to/367d5iY">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
Children's creativity is limitless, but you must also give them 
the space to develop it. With this Tracing Pad this is possible.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ tracing_pad }
           alt="Tracing Pad for girls"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Crayola Light Up Tracing Pad Pink, AMZ Exclusive
        </h4>
      </div>
      <a href= "https://amzn.to/2GHqhTg">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
Some children are a bit smarter than others 
and often ask a lot of questions. With this book you can ask a 
few counter-questions and get little Einstein to think about some 
difficult riddles.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ smart_kids_riddles }
           alt="Riddles for smart Children"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          Difficult Riddles For Smart Kids: 300 Difficult Riddles 
          And Brain Teasers Families Will Love (Books for Smart Kids) 
        </h4>
      </div>
      <a href= "https://amzn.to/38kN1Up">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
And if the puzzles are not enough, your child will find 
5000 facts in this book that he or she is guaranteed not to know!
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ fivethousand_awesome_facts }
           alt= "Random Gift - 5000 awesome facts for kids"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>
          5000 Awesome Facts About Everything (National Geographic Kids)
        </h4>
      </div>
      <a href= "https://amzn.to/2U62Aam">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>
<p>
With this random gift, your child will train its dexterity and 
concentration and don't worry, the arrows are not dangerous. So if a 
shot misses, there is no danger.
</p>
<section className="products">
 <div className="product-card">
    <div className="product-image">
      <img src={ arrow_toy }
           alt="Arrow toy for boys"
      />
    </div>
    <div className="product-description">
      <div className="product-info">
        <h4>        
            Marky Sparky Faux Bow 3 - Shoots Over 100 Feet
             - Foam Bow & Arrow Archery Set
        </h4>
      </div>
      <a href= "https://amzn.to/38mIrFm">
        <button className="product-button">🛒 Buy on Amazon</button>
      </a>
   </div>   
  </div>
</section>  
</div>
</div> 

 )
 }
  
}

export default Home
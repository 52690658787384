import React from "react";
import Image from "react-bootstrap/Image";
import ProductPlaceholder from "../ProductPlaceholder.js";
import "../Home.css";
import logo from "./logo.png";
import Product from "../Product.js";
import parcel from "../parcel.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class RandomProduct extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      ButtonText: "RANDOM PRODUCT",
      showedProduct: false,
      productError: false,
      loadedProductInitially: false,
      productData: {
        asins: {
          bildscr: null,
          name: null,
          link: null,
        },
      },
      category: "all",
    };

    this.submittedCategory = this.submittedCategory.bind(this);
  }

  submittedCategory() {
    this.setState({
      loading: true,
    });
    if (this.state.category == "all") {
      fetch("https://randomazonbackend.appspot.com/product/")
        .then((res) => res.json())
        .then((data) =>
          this.setState({
            productError: false,
            productData: data,
            showedProduct: true,
            loadedProductInitially: false,
          })
        );
      
    } else {
      fetch(
        "https://randomazonbackend.appspot.com/product/" +
          "?category=" +
          this.state.category
      )
        .then((res) => res.json())
        .then((data) =>
          this.setState({
            productError: false,
            productData: data,
            showedProduct: true,
            loadedProductInitially: false,
          })
        );
    }
  }

  handleSelectChange = (event) => {
    this.setState({
      category: event.target.value,
    });
  };
  ProductShowed = () => {
    this.setState({
      showedProduct: true,
      loadedProductInitially: false,
    });
  };

  setLoading = (loadingstate) => {
    this.setState({
      loading: loadingstate,
      productError: false
    });
  };

  getLoading = () => {
    return this.state.loading
  };

  setProductError = (productErrorState) => {
    /* this.setState({
      productError: productErrorState
    }); */
    this.submittedCategory();
  };

  render() {
    return (
      <div className="MainContainer">
        <div className="LeftContainer">
          <Image src={logo} className="Logo" />
          <p className="Description">
            {" "}
            We help you to get creative inspiration and quick ideas what to buy
            online!Randomicle will show you random popular articles off all
            categories on Amazon.Just click the Button below!{" "}
          </p>{" "}
          <h3
            style={{
              color: "rgb(250, 155, 13)",
            }}
          >
            <b> CATEGORY </b>{" "}
          </h3>{" "}
          <select
            onChange={this.handleSelectChange}
            style={{
              border: "2px solid  rgb(250, 155, 13)",
              borderRadius: "10px",
              marginTop: "5px",
              marginBottom: "15px",
              backgroundColor: "white",
            }}
          >
            <option value="all"> All </option>{" "}
            <option value="amazon_pantry"> Amazon Pantry </option>{" "}
            <option value="arts-craft"> Arts and Craft </option>{" "}
            <option value="automotive"> Automotive </option>{" "}
            <option value="baby"> Baby </option>{" "}
            <option value="beauty_and_personal_care"> Beauty </option>{" "}
            <option value="computer"> Computer </option>{" "}
            <option value="courses"> Courses </option>{" "}
            <option value="electronics"> Electronics </option>{" "}
            <option value="games"> Games </option>{" "}
            <option value="grocery_and_gourmets"> Grocery </option>{" "}
            <option value="home"> Home </option>{" "}
            <option value="kitchen"> Kitchen </option>{" "}
            <option value="men_active_wear"> Men - Active Wear </option>{" "}
            <option value="men_jeans"> Men - Jeans </option>{" "}
            <option value="men_sneaker"> Men - Sneaker </option>{" "}
            <option value="men_tees"> Men - Tees </option>{" "}
            <option value="outdoor"> Outdoor </option>{" "}
            <option value="sport"> Sport </option>{" "}
            <option value="tools"> Tools </option>{" "}
            <option value="toys"> Toys </option>{" "}
            <option value="women_active_wear"> Women - Active Wear </option>{" "}
            <option value="women_dresses"> Women - Dresses </option>{" "}
            <option value="women_handbags"> Women - Handbags </option>{" "}
            <option value="women_jeans"> Women - Jeans </option>{" "}
            <option value="women_jewelry"> Women - Jewellery </option>{" "}
            <option value="women_shoes"> Women - Shoes </option>{" "}
            <option value="women_sneaker"> Women - Sneaker </option>{" "}
            <option value="women_watches"> Women - Watches </option>{" "}
          </select>{" "}
          {this.state.loadedProductInitially ? (
            <button className="StartButton" onClick={this.submittedCategory}>
              {" "}
              {this.state.ButtonText}{" "}
            </button>
          ) : (
            <button className="StartButton" onClick={this.submittedCategory}>
              {" "}
              {this.state.ButtonText}{" "}
            </button>
          )}{" "}
        </div>{" "}
        {this.state.showedProduct ? (
          <div className="RightContainer">
            
             { this.state.loading &&
              <div className = "BoxPlaceholder"><ProductPlaceholder/></div>
              }
              <div className="ProductBox"  style = {{display: this.state.loading ? "none" : "block"}}>
               { this.state.productError ? ("Oops, soemthing went wrong!" ) : (
              <Product            
                url={
                  "https://amazon.com/dp/" +
                  this.state.productData.ASIN +
                  "/?tag=randomicle0c-20"
                }
                imageUrl={
                  "https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=" +
                  this.state.productData.ASIN +
                  "&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL500"
                }
                setLoading = { this.setLoading.bind(this)}
                getLoading = { this.getLoading.bind(this)}
                setProductError = { this.setProductError.bind(this)}
              /> ) }
              </div>          
          </div>
        ) : (
          <div className="ParcelContainer">
            {" "}
            <Image src={parcel} className="Parcel" />{" "}
          </div>
        )}{" "}
      </div>
    );
  }
}

export default RandomProduct;

import React from "react"

import './Product.css';
import ProductPlaceholder from "./ProductPlaceholder";

class Product extends React.Component{

    giveLoadFeedback = () => {
      this.props.setLoading(false);
    }

    giveErrorFeedback = () => {
      this.props.setLoading(false);
      this.props.setProductError(true);
    }

    render() {
      return (        
        <div className = "Box">
          <div className="Imagebox"> 
            <a className="ImageLink" 
                href = {this.props.url}
                target = "blank" > 
                <img className = "Image" 
                      onLoad={() => this.giveLoadFeedback()} 
                      onError={() => this.giveErrorFeedback()} 
                      alt =""
                      src = {this.props.imageUrl} />
              </a >
          </div>
            <button className = "button1"
            onClick = {
             () => window.open(this.props.url, "_blank")
            } > ABOUT THE PRODUCT </button>    
        </ div>)
    }
}
export default Product;
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom"
import  Home  from './Home';
import { About } from './About';
import { Contact } from './Contact';
import {Helmet} from 'react-helmet'
import { NavigationBar } from './components/NavigationBar';

class App extends Component {
  render() {
    return(
      <div>
      <Helmet>
      <title>Random Amazon Products | Best Random Gift Generator | 50.000+ Products!</title>
      <meta name="description" 
            content="► Randomicle generates Random Amazon Products: More than 20 Categories ✓ Database with more than 50k products ⭐ Find perfect gift Ideas!" />
    </Helmet>
      <React.Fragment>
      <Router>
        <NavigationBar />
          <Switch >
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
          </Switch>
    </Router>
    </React.Fragment>
    </div>
    );
  }
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import Image from 'react-bootstrap/Image'
import logo from './logo.png'

const Styles = styled.div`
  .navbar {
    background-color: white;
     box-shadow:0 1px 1px 0px #19162b;
     border-bottom: 0px solid rgb(66, 66, 66);
  }
  a, .navbar-brand, .nav-link, .navbar-nav {
    color: rgb(66, 66, 66);
    padding-left:20px;
    padding-right:20px;
    margin-left:20px;
    
    font-size: 18px;
    font-weight: bold;
    &:hover {
      color: #ffba3b;
    }
    .nav-link {
   /*    border-left: 1.5px solid rgb(66, 66, 66);
      border-right: 1.5px solid rgb(66, 66, 66); */
    }


`;

export const NavigationBar = () => (
  <Styles>
    <Navbar expand="lg">
      <Navbar.Brand href="/"><Image src={logo} className="Logo2" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link>
              <Link to="/">Home</Link>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link>
              <Link to="/about">About</Link>
            </Nav.Link>
          </Nav.Item>
         
          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles >
)